<template>
  <div style="width: 100%; padding: 0 20px">
    <div class="configure">
      <div class="title">
        <span class="title-ys">基本信息</span>
      </div>
      <div style="margin-bottom: 72px">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="作业标题:" prop="name">
            <el-input style="width: 60%" v-model="ruleForm.name"></el-input>
          </el-form-item>
          <el-form-item label="发送学员:">
            <div>
              <el-button
                type="primary"
                @click="JumpUrl('/parentCenter/Assignment')"
                >选 择</el-button
              >
            </div>
            <el-table
              :data="datalist_enterprise"
              height="300px"
              style="width: 60%"
              border
              class="myClient-top-20"
            >
              <el-table-column
                property="className1"
                label="班级/一对一"
                align="left"
              ></el-table-column>
              <el-table-column
                property="schoolName1"
                label="学员姓名"
                align="left"
              ></el-table-column>
            </el-table>
          </el-form-item>
          <div class="title">
            <span class="title-ys">作业详情</span>
          </div>

          <el-form-item label="详情" style="width: 100%">
            <editor
              v-model="form.wap_desc"
              :initial-val="editor2Val"
              style="width: 60%"
              key-name="wap_desc"
              @change="onAdvantageEditChange"
            ></editor>
          </el-form-item>
          <el-form-item label="视频" style="width: 100%">
            <el-upload
              ref="videoUpload"
              action="#"
              accept=".mp4"
              :limit="3"
              :file-list="videoUpload"
              :on-change="viedoChangefile"
              list-type="picture-card"
              :auto-upload="false"
            >
              <i slot="default" class="el-icon-plus"
                ><br />
                <i style="font-style: normal; font-size: 14px">上传视频</i></i
              > 
              <div
                slot="file"
                v-for="(item, index) in videoUpload"
                :key="index"
                slot-scope="{ file }"
              >
                <video
                  class="el-upload-list__item-thumbnail"
                  :src="item.url"
                  alt=""
                />
                <span class="el-upload-list__item-actions">
                  <span
                    v-if="videoUpload.length"
                    class="el-upload-list__item-delete"
                    @click="videoRemove(file)"
                  >
                    <i class="el-icon-delete"></i>
                  </span>
                </span>
              </div>
              <div slot="tip" class="el-upload__tip">
                视频大小不超过200M，最多上传3个
              </div>
            </el-upload>
            <el-dialog :visible.sync="videoVisible">
              <img width="100%" :src="videoUpload" alt="" />
            </el-dialog>
          </el-form-item>
          <el-form-item label="图片" style="width: 100%">
            <el-upload
              ref="pictureUpload"
              action="#"
              :on-change="pictureChangefile"
              :file-list="pictureUpload"
              list-type="picture-card"
              :limit="12"
              :auto-upload="false"
            >
              <i slot="default" class="el-icon-plus"
                ><br />
                <i style="font-style: normal; font-size: 14px">上传图片</i></i
              >
              <div
                slot="file"
                v-for="(item, index) in pictureUpload"
                :key="index"
                slot-scope="{ file }"
              >
                <img
                  class="el-upload-list__item-thumbnail"
                  :src="item.url"
                  alt=""
                />
                <span class="el-upload-list__item-actions">
                  <span
                    class="el-upload-list__item-preview"
                    @click="handlePictureCardPreview(file)"
                  >
                    <i class="el-icon-zoom-in"></i>
                  </span>
                  <span
                    v-if="!disabled"
                    class="el-upload-list__item-delete"
                    @click="pictureRemove(file)"
                  >
                    <i class="el-icon-delete"></i>
                  </span>
                </span>
              </div>
              <div slot="tip" class="el-upload__tip">
                图片大小不超过9M，最多上传12张
              </div>
            </el-upload>
            <el-dialog :visible.sync="dialogVisible">
              <img width="100%" :src="dialogImageUrl" alt="" />
            </el-dialog>
          </el-form-item>
          <el-form-item label="录音文件" style="width: 100%">
            <el-upload
              class="upload-demo"
              action=""
              :on-preview="handlePreview"
              :on-remove="handleRemove"
              :before-upload="handleUpload"
              :before-remove="beforeRemove"
              multiple
              :limit="3"
              accept=".mp3"
              :on-exceed="handleExceed"
              :file-list="fileList"
            >
              <el-button size="small" type="primary">点击上传</el-button>
              <div slot="tip" class="el-upload__tip">
                支持格式：mp3,最多上传600秒
              </div>
            </el-upload>
          </el-form-item>
          <el-form-item label="">
            <el-checkbox-group v-model="form.type">
              <el-checkbox
                label="学员提交作业后，将对本班所有人可见"
                name="type"
              ></el-checkbox>
            </el-checkbox-group>
            <el-checkbox-group v-model="form.type1">
              <el-checkbox
                label="学员家长可通过家校中心提交作业"
                name="type"
              ></el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="fabu_position flex justify-content-center">
      <el-button type="primary" @click="submitForm('ruleForm')"
        >发布作业</el-button
      >
      <el-button>存草稿</el-button>
    </div>
  </div>
</template>
<script>
import Editor from "@/components/Editor/index";
export default {
  components: { Editor },
  data() {
    return {
      fileList: [], //录音文件
      videoUpload: [],
      pictureUpload: [],
      dialogImageUrl: "", //图片上传
      dialogVisible: false, //图片上传
      disabled: false, //图片上传
      videoVisible: false,
      datalist_enterprise: [],
      form: {
        wap_desc: "",
        type: "",
        type1: "",
      },
      editor2Val: "",
      ruleForm: {
        name: "",
      },
      rules: {
        name: [
          { required: true, message: "请输入作业标题", trigger: "blur" },
          // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
      },
    };
  },
  methods: {
    //录音文件上传
    getTimes(file) {
      return new Promise((resolve) => {
        var content = file;
        //获取录音时长
        var url = URL.createObjectURL(content);
        //经测试，发现audio也可获取视频的时长
        var audioElement = new Audio(url);
        audioElement.addEventListener("loadedmetadata", (_event) => {
          var audioDuration = parseInt(audioElement.duration);
          // conssole.log(this.audioDuration);
          resolve(audioDuration);
        });
      });
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },

    handleUpload(file) {
      this.getTimes(file).then((val) => {
        if (val <= 600) {
          this.$message({
            message: "录音为" + val + "秒，可上传",
            type: "success",
          });
        } else {
          this.$message.error("录音为：" + val + "秒，最多上传600秒");
        }

        console.log("音频长度:", val);
      });
      console.log();
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    //录音文件上传

    //图片上传
       handlePictureCardPreview(file) {
        this.dialogImageUrl = file.url;
        this.dialogVisible = true;
      },
    pictureRemove(file) {
      this.pictureUpload.splice(
        this.pictureUpload.findIndex((t) => t.url === file.url),
        1
      );
    },
    pictureChangefile(file, b) {
      var _this = this;
      //   const isJPG =
      // file.type.toLowerCase() === "image/bmp" ||
      // file.type.toLowerCase() === "image/png" ||
      // file.type.toLowerCase() === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024;
      console.log(isLt2M);
      // if (!isJPG) {
      //   this.$message.error("上传图片只能是 bmp，jpg,png格式!");
      // }
      if (isLt2M > 9) {
        this.$message.error("上传图片大小不能超过9MB!");

        //   if(!_this.pictureUpload.length){

        // this.$refs.pictureUpload.clearFiles();
        //   }
      } else {
        _this.pictureUpload.push({
          name: "",
          url: URL.createObjectURL(file.url),
        });
        console.log(this.pictureUpload);
      }
    },

    //图片上传

    //视频上传

    viedoChangefile(file, b) {
      var _this = this;
      const isLt2M = file.size / 1024 / 1024 < 200;
      if (!isLt2M) {
        this.$message.error("上传视频大小不能超过 200MB!");
      } else {
        console.log(file);
        _this.videoUpload.push({
          name: "",
          url: URL.createObjectURL(file.raw),
        });
        console.log(this.videoUpload);
      }
    },
    videoRemove(file) {
      this.videoUpload.splice(
        this.videoUpload.findIndex((t) => t.url === file.url),
        1
      );
    },
    // videoPictureCardPreview(file) {
    //   console.log(file)
    //   this.videoUpload = file.url;
    //   this.videoVisible = true;
    // },
    // videoDownload(file) {
    //   console.log(this.videoUpload);
    //   this.videoUpload.splice(file.url, 1);
    // },
    //视频上传
    onAdvantageEditChange(type, html) {
      this.form[type] = html;
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert("submit!");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>

<style lang="scss">
.fabu_position { 
  position: fixed;
  width: 76%;
  bottom: 20px;
  left: 338px;
}
.configure {
  width: 100%;
  background-color: #fff;
  margin-top: 10px;
  padding: 10px;
}
.title {
  font-size: 18px;
  font-weight: bold;
  display: flex;
  .title-ys {
    border-left: 5px solid rgb(72, 172, 211);
    padding: 0 9px;
  }
}
.status-point {
  background-color: #e6a23c;
  margin-right: 5px;
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
}
</style>